.techs {
  padding: 100px 70px;
  background-color: var(--second-bg-color);
}

.techs__subtitle {
  margin: 90px 0 0;
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  line-height: 58px;
}

.techs__description {
  max-width: 460px;
  margin: 26px auto 0;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.techs__list-wrapper {
  display: flex;
  justify-content: center;
}

.techs__list {
  margin: 100px 0 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  list-style: none;
}

.techs__list-elem {
  width: 90px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--third-bg-color);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

@media (max-width: 768px) {
  .techs {
    padding: 90px 50px;
  }

  .techs__subtitle {
    margin-top: 80px;
  }

  .techs__description {
    margin-top: 22px;
    font-size: 12px;
    line-height: 18px;
  }

  .techs__list {
    margin-top: 83px;
    flex-wrap: wrap;
  }

  .techs__list-elem {
    width: 84px;
    height: 57px;
    font-size: 12px;
    line-height: 15px;
  }
}

@media (max-width: 576px) {
  .techs {
    padding: 70px 18px;
    background-color: var(--main-bg-color);
  }

  .techs__subtitle {
    margin-top: 60px;
    font-size: 30px;
    line-height: 36px;
  }

  .techs__description {
    max-width: 292px;
    margin-top: 24px;
    font-size: 11px;
    line-height: 16px;
  }

  .techs__list {
    justify-content: start;
    margin-top: 50px;
    width: 178px;
  }

}
