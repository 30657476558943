.app {
  max-width: 1280px;
  min-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  color: var(--text-main-color);
  letter-spacing: -0.04em;
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
