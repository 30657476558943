.popup {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s, visibility 0.25s 0.25s;
}

.popup_opened {
  opacity: 1;
  visibility: visible;
  transition: opacity .5s, visibility .5s;
}

.popup__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup__wrapper {
  position: relative;
  width: 430px;
  min-height: 320px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-bg-color);
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15);
}

.popup__text {
  margin: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: var(--text-main-color);
  letter-spacing: 0.04em;
}

.popup__close-btn {
  position: absolute;
  top: -34px;
  right: -34px;
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url(../../images/close-icon.svg);
  background-color: unset;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .popup__wrapper {
    width: 282px;
    padding: 24px;
  }

  .popup__text {
    font-size: 18px;
    line-height: 27px;
  }

  .popup__close-btn {
    width: 24px;
    height: 24px;
    top: -36px;
    right: 0;
  }
}
