.nav-tab {
  margin: 151px auto 0;
  padding-bottom: 30px;
}

.nav-tab__links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.nav-tab__link-wrapper {
  width: 96px;
  height: 36px;
  cursor: pointer;
  background-color: var(--third-bg-color);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.nav-tab__link {
  text-decoration: none;
  color: #fff;
  letter-spacing: normal;
}

@media (max-width: 768px) {
  .nav-tab {
    margin-top: 314px;
  }
}

@media (max-width: 576px) {
  .nav-tab {
    margin-top: 212px;
    padding-bottom: 17px;
  }

  .nav-tab__link-wrapper {
    width: 82px;
    height: 26px;
    font-size: 10px;
    border-radius: 6px;
  }
}
