.line {
  margin: 23px 0 0;
  height: 1px;
  border: none;
  background-color: var(--third-color);
}

.line_place_portfolio {
  margin: 9px 0 20px;
  background-color: var(--second-color);
}

.line_place_footer {
  margin: 20px 0 0;
}

.line_place_movie {
  margin: 13px 0 0;
}

.line_place_profile {
  margin: 3px 0 0;
}

@media (max-width: 576px) {
  .line {
    margin-top: 28px;
  }

  .line_place_portfolio {
    margin-top: 19px;
  }

  .line_place_footer {
    margin-top: 21px;
  }

  .line_place_movie {
    margin-top: 13px;
  }

  .line_place_profile {
    margin-top: 3px;
  }
}
