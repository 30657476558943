@import url(../../blocks/line/line.css);

.footer {
  padding: 79px 70px 20px;
}

.footer__title {
  margin: 0;
  font-weight: 400;
  color: var(--main-color);
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: normal;
}

.footer__wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.footer__copyright {
  margin: 0;
  letter-spacing: normal;
}

.footer__links {
  margin: 0;
  display: flex;
  letter-spacing: normal;
  list-style: none;
}

.footer__link-wrapper {
  margin-right: 20px;
}

.footer__link-wrapper:last-child {
  margin-right: 0;
}

.footer__link {
  text-decoration: none;
  color: var(--text-main-color);
}

@media (max-width: 768px) {
  .footer {
    padding: 79px 30px 20px;
  }
}

@media (max-width: 576px) {
  .footer {
    padding: 79px 10px 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .footer__title {
    font-size: 12px;
    line-height: 15px;
  }

  .footer__wrapper {
    margin: 30px 0 0;
    flex-direction: column-reverse;
    align-items: center;
  }

  .footer__links {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .footer__link-wrapper {
    margin: 12px 0 0;
    font-size: 12px;
    line-height: 15px;
  }

  .footer__link-wrapper:first-child {
    margin-top: 0;
  }

  .footer__link {
    text-align: center;
    margin: 0;
  }

  .footer__copyright {
    margin-top: 30px;
    color: var(--main-color);
  }
}
