.title {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.04em;
}

@media (max-width: 576px) {
  .title {
    font-size: 18px;
    line-height: 22px;
  }
}
