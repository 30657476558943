.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 74px;
  padding: 0 70px 0;
}

.header__logo {
  height: 38px;
  width: 38px;
  cursor: pointer;
  background-image: url('../../images/logo.svg');
}

@media (max-width: 768px) {
  .header {
    padding: 0 30px 0;
  }
}

@media (max-width: 576px) {
  .header {
    padding: 0 14px 0;
  }
}
